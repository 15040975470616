import { HashRouter, Route, Redirect } from 'react-router-dom'
import OpenOrdersPage from './pages/OpenOrdersPage'
import React from 'react'
import BalancesPage from './pages/BalancesPage'
import BasicLayout from './componentsv2/BasicLayout'
import CommonErrorPage from './pages/CommomErrorPage'
import SwapPage from './pages/SwapPage'

export function Routes() {
  return (
    <HashRouter basename={'/'}>
      <Route exact path="/">
        <Redirect to={'/swap'} />
      </Route>
      <Route exact path="/mintinglab">
        <Redirect to={'/swap'} />
      </Route>
      <Route exact path="/trade">
        <Redirect to={'/swap'} />
      </Route>
      <Route exact path="/analytics">
        <Redirect to={'/swap'} />
      </Route>
      <Route exact path="/help">
        <Redirect to={'/swap'} />
      </Route>
      <Route exact path="/swap" component={OrderBookBaseSwapContents} />
      <Route exact path="/v2/my/orders" component={OpenOrdersPageContents} />
      <Route exact path="/v2/my/balances" component={BalancesPageContents} />
      <Route exact path="/error" component={CommonContents} />
    </HashRouter>
  )
}

function CommonContents() {
  return (
    <BasicLayout>
      <CommonErrorPage />
    </BasicLayout>
  )
}

function OpenOrdersPageContents() {
  return (
    <BasicLayout>
      <OpenOrdersPage />
    </BasicLayout>
  )
}

function BalancesPageContents() {
  return (
    <BasicLayout>
      <BalancesPage />
    </BasicLayout>
  )
}

function OrderBookBaseSwapContents() {
  return (
    <BasicLayout>
      <SwapPage />
    </BasicLayout>
  )
}
