import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './i18n'
import { ThemeProvider } from './utils/Contexts/ThemeContext'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'G-HGYYSFQE30',
  events: {
    sendUserInfo: 'userInfo',
  },
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  // <React.StrictMode>
  <ThemeProvider>
    <App />
  </ThemeProvider>,
  // </React.StrictMode>
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
