import { DexLabRecentPrice, DexLabClosingPrice, DexLabMarketV2Info, DexLabHourPrice } from './../types'
import { DEXLAB_API_HOST } from '../../application'

export default class DexLabApi {
  static URL: string = `${DEXLAB_API_HOST}/`

  static async get(path: string) {
    try {
      const response = await fetch(this.URL + path)
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error fetching from DexLabApi API ${path}: ${err}`)
    }
    return null
  }

  static async getBody(path: string, body: any) {
    try {
      const response = await fetch(this.URL + path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error fetching from DexLabApi API ${path}: ${err}`)
    }
    return null
  }

  // 모든 마켓의 전일가 조회
  static async getAllMarketCurrent24hPriceAndLastPrice(): Promise<DexLabRecentPrice[] | null> {
    return DexLabApi.get(`v1/prices/recent`)
  }

  // 특정 마켓의 전일(24h)가 조회
  static async getMarketCurrent24hPrice(marketAddress): Promise<DexLabClosingPrice | null> {
    return DexLabApi.get(`v1/prices/${marketAddress}/closing-price`)
  }

  // 특정 토큰의 USDC마켓
  static async getMarketLastPriceForUsdc(symbol): Promise<DexLabClosingPrice | null> {
    return DexLabApi.get(`v1/prices/${symbol}/last-usdc`)
  }

  // market v2
  // 특정 토큰의 USDT마켓 가격 조회(비상장 토큰은 조회 불가)
  static async getAllMarketsV2(): Promise<DexLabMarketV2Info[] | null> {
    return DexLabApi.get(`v2/markets?summary=Y`)
  }

  // 전체 비인증 마켓리스트 조회
  static async getExtraMarketList(): Promise<DexLabMarketV2Info[] | null> {
    return DexLabApi.get(`v2/markets?summary=Y&type=UNCERTIFIED,NOT_LISTING`)
  }

  static async getStarAtlasMarketList(): Promise<DexLabMarketV2Info[] | null> {
    return DexLabApi.get(`v2/markets?type=STAR_ATLAS`)
  }

  // 베이스 토큰주소로 마켓조회
  static async getAllMarketV2ByBaseTokenIds(ids): Promise<DexLabMarketV2Info[] | null> {
    return DexLabApi.getBody(`v2/markets/base`, ids)
  }

  // 특정 마켓 1시간 단위 24시간 차트 데이터 조회
  static async getMarketHourPriceByAddress(address): Promise<DexLabHourPrice[] | null> {
    return DexLabApi.get(`v1/prices/hour/${address}`)
  }
}
