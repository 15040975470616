import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { useInterval } from 'react-use'
import FloatingElement from '../componentsv2/layout/FloatingElement'
import { useAllMarkets, useAllOpenOrders, getTradePageUrl, MarketProvider } from '../utils/dex-markets'
import OpenOrderTable from '../componentsv2/UserInfoTable/OpenOrderTable'
import { Button, Row, Spin } from 'antd'
import { OrderWithMarketAndMarketName, DexLabMarketV2Info } from '../utils/types'
import { useWallet } from '../utils/wallet'
import WalletConnectGuide from '../componentsv2/WalletConnectGuide'
import { useTranslation } from 'react-i18next'
import DexlabApi from '../utils/client/dexlabApiConnector'

export default function OpenOrdersPage() {
  document.title = `Open Orders - The best DEX platform on SOLANA.`

  const { connected } = useWallet()
  const { marketAddress } = useParams()

  const [allV2Markets, setAllV2Markets] = useState<DexLabMarketV2Info[]>([])
  const { openOrders, openOrderMarketList, loaded, refreshOpenOrders } = useAllOpenOrders(allV2Markets)
  const [firstOrderBookLoading, setFirstOrderBookLoading] = useState(true)

  useInterval(() => {
    setFirstOrderBookLoading(false)
  }, 8000)

  useEffect(() => {
    if (marketAddress) {
      localStorage.setItem('marketAddress', JSON.stringify(marketAddress))
    }
  }, [marketAddress])
  const history = useHistory()
  function setMarketAddress(address) {
    history.push(getTradePageUrl(address))
  }

  useEffect(() => {
    async function getAllMarkets() {
      const response = await DexlabApi.getAllMarketsV2()
      if (response) {
        setAllV2Markets(response)
      } else {
        history.push(`/error`)
      }
    }
    getAllMarkets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!connected) {
    return (
      <Row
        justify="center"
        style={{
          marginTop: '10%',
        }}
      >
        <WalletConnectGuide />
      </Row>
    )
  }

  return (
    // @ts-ignore
    <FloatingElement style={{ flex: 1, paddingTop: 30 }}>
      {!_.isEmpty(allV2Markets) ? (
        <MarketProvider allV2Markets={allV2Markets} marketAddress={marketAddress} setMarketAddress={setMarketAddress}>
          <InnerPage
            allV2Markets={allV2Markets}
            openOrderMarketList={openOrderMarketList}
            openOrders={openOrders}
            loaded={loaded && !firstOrderBookLoading}
            refreshOpenOrders={refreshOpenOrders}
          />
        </MarketProvider>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '30px' }}>
          <Spin size="large" />
        </div>
      )}
    </FloatingElement>
  )
}

function InnerPage({ allV2Markets, openOrderMarketList, openOrders, refreshOpenOrders, loaded }) {
  const { t: trText } = useTranslation()

  let marketAddressesToNames = Object.fromEntries(allV2Markets.map((info) => [info.address, info.symbol]))
  let [allMarkets] = useAllMarkets(allV2Markets, openOrderMarketList ?? [], true)

  const marketsByAddress = Object.fromEntries(
    (allMarkets || []).map((marketInfo) => [marketInfo.market.address, marketInfo.market]),
  )

  const allDataSource: OrderWithMarketAndMarketName[] = (openOrders || [])
    .map((orderInfos) =>
      orderInfos.orders.map((order) => {
        return {
          marketName: marketAddressesToNames[orderInfos.marketAddress],
          market: marketsByAddress[orderInfos.marketAddress],
          ...order,
        }
      }),
    )
    .flat()
  return (
    <>
      <Button onClick={refreshOpenOrders} loading={!loaded}>
        {trText('refresh')}
      </Button>
      <OpenOrderTable
        openOrders={allDataSource}
        pageSize={25}
        loading={!loaded}
        onCancelSuccess={refreshOpenOrders}
        marketFilter
      />
      <div style={{ marginTop: '10px', fontSize: '13px' }}>
        <span>* If an order is canceled, it will be reflected after 30s ~ 1m due to transaction confirmation.</span>
        <br />
        <span>
          * Check the progress through the <b>Transaction History</b> tab.
        </span>
      </div>
    </>
  )
}
