import Wallet from '@project-serum/sol-wallet-adapter'
import React from 'react'
import { notify } from '../../utils/notifications'

export function SolletExtensionAdapter(_, network) {
  if ((window as any) && (window as any).sollet) {
    const sollet = (window as any).sollet
    if (sollet) {
      return new Wallet(sollet, network)
    }
  }
  return {
    on: () => {},
    connect: () => {
      notify({
        message: 'Connection Error',
        description: (
          <>
            Please install{' '}
            <a
              href="https://chrome.google.com/webstore/detail/sollet/fhmfendgdocmcbmfikdcogofphimnkno"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sollet Wallet Extension
            </a>
          </>
        ),
      })
    },
  }
}
