import React, { useState } from 'react'
import DataTable from '../layout/DataTable'
import _ from 'lodash'
import { Button, Row, Avatar } from 'antd'
import { settleAllFunds } from '../../utils/send'
import { notify } from '../../utils/notifications'
import { useDexlabRpcSendConnection } from '../../utils/connection'
import { useWallet } from '../../utils/wallet'
import { useSelectedTokenAccounts, useTokenAccounts } from '../../utils/dex-markets'
import { useTranslation } from 'react-i18next'
import NotConnectView from '../../componentsv2/element/NotConnectView'
import { DexLabMarketV2Info } from '../../utils/types'

export default function MarketBalancesSettleTable({
  allV2Markets,
  walletBalances,
  allMarkets,
  allMarketsConnected,
}: {
  allV2Markets: DexLabMarketV2Info[]
  walletBalances: {
    coin: string
    mint: string
    walletBalance: number
    openOrdersFree: number
    openOrdersTotal: number
    marketAddress: string
  }[]
  allMarkets: any[]
  allMarketsConnected: boolean
}) {
  const { t: trText } = useTranslation()
  const isLoading = _.isEmpty(allMarkets)

  const connection = useDexlabRpcSendConnection()
  const { wallet, connected } = useWallet()
  const [selectedTokenAccounts] = useSelectedTokenAccounts()
  const [tokenAccounts, tokenAccountsConnected] = useTokenAccounts()
  const [settlingFunds, setSettlingFunds] = useState(false)

  async function onAllSettleFunds(settleMarket) {
    setSettlingFunds(true)
    try {
      if (!tokenAccounts || !tokenAccountsConnected) {
        notify({
          message: trText('toast_message_wallet_settle_error'),
          description: trText('toast_message_not_connect'),
          type: 'error',
        })
        return
      }
      if (!settleMarket || !allMarketsConnected) {
        notify({
          message: trText('toast_message_wallet_settle_error'),
          description: trText('toast_message_not_market'),
          type: 'error',
        })
        return
      }

      await settleAllFunds({
        connection,
        tokenAccounts,
        selectedTokenAccounts,
        wallet: wallet!!,
        markets: [settleMarket.market],
      })
    } catch (e: any) {
      notify({
        message: trText('toast_message_wallet_settle_error'),
        description: e.message,
        type: 'error',
      })
    } finally {
      setSettlingFunds(false)
    }
  }

  const columns = [
    {
      title: 'Market',
      width: '20%',
      render: (market) => {
        const findTokenInfo = allV2Markets
          .filter((f) => !f.nameEn.includes('[Deprecated]'))
          .find((f) => f.baseMint === market.mint)
        return (
          <>
            <Row align="middle">
              {findTokenInfo ? (
                <>
                  <Avatar src={findTokenInfo.iconUrl} size={30} />
                  <span style={{ color: '#FFFFFF' }}>{` ${findTokenInfo.nameEn ?? market.coin}`}</span>
                </>
              ) : (
                <>
                  <Avatar
                    style={{ marginRight: '3px' }}
                    src={`https://raw.githubusercontent.com/dexlab-project/assets/master/tokens/solana/${market.coin.toLowerCase()}/symbol.png?raw=true`}
                    size={30}
                  />
                  {market.coin}
                </>
              )}
            </Row>
          </>
        )
      },
    },
    {
      title: 'Token',
      width: '20%',
      render: (market) => market.coin,
    },
    {
      title: 'Amount',
      width: '20%',
      render: (market) => market.unsettleAmount,
    },
    {
      title: 'Settle',
      width: '20%',
      render: (market) => (
        <div style={{ textAlign: 'left' }}>
          {market.isSettle ? (
            <Button
              ghost
              style={{ marginRight: 12, color: true ? '#FFFFFF' : '#484848', border: '1px solid #484848' }}
              onClick={() => onAllSettleFunds(market)}
            >
              {trText('standalone_balances_settle')}
            </Button>
          ) : (
            <>
              When the market is settled,
              <br />
              it is processed together.
            </>
          )}
        </div>
      ),
    },
  ]

  const allMarketSettleData: any[] = []
  const unSettleTokens = walletBalances.filter((f) => f.coin && Number(f.openOrdersFree) > 0)
  unSettleTokens.forEach((token, idx) => {
    const findMarket = allMarkets.find((m) => m.market.address.toBase58() === token.marketAddress)
    if (findMarket) {
      allMarketSettleData.push({
        key: idx,
        mint: token.mint,
        coin: token.coin,
        marketAddress: token.marketAddress,
        unsettleAmount: token.openOrdersFree,
        isSettle: true,
        ...findMarket,
      })
    }
  })

  return (
    <React.Fragment>
      {connected ? (
        <DataTable
          emptyLabel={`No Unsettled`}
          dataSource={allMarketSettleData}
          loading={isLoading || settlingFunds}
          columns={columns}
          pagination={false}
        />
      ) : (
        <NotConnectView />
      )}

      {/* {connected && (
        <Button
          onClick={() => {
            onAllSettleFunds(allMarketSettleData)
          }}
          loading={settlingFunds || !tokenAccountsConnected}
        >
          {trText('standalone_balances_settle_all')}
        </Button>
      )} */}
    </React.Fragment>
  )
}
