import { DexLabMarketV2Info, DexLabRecentPrice, DexlabMarketLpPoonInfo } from './../types'
import { DEXLAB_MARKLET_API_HOST } from '../../application'

export default class DexLaMarketbApi {
  static URL: string = `${DEXLAB_MARKLET_API_HOST}/`

  static async get(path: string) {
    try {
      const response = await fetch(this.URL + path)
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error fetching from DexLaMarketbApi API ${path}: ${err}`)
    }
    return null
  }

  static async getBody(path: string, body: any) {
    try {
      const response = await fetch(this.URL + path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error fetching from DexLaMarketbApi API ${path}: ${err}`)
    }
    return null
  }

  // market v2
  // 특정 토큰의 USDT마켓 가격 조회(비상장 토큰은 조회 불가)
  static async getAllMarketsV2(): Promise<DexLabMarketV2Info[] | null> {
    return DexLaMarketbApi.get(`v2/markets?summary=Y`)
  }

  // 특정 마켓의 레이디움 LP Pool 정보 조회
  static async getMarketLpPool(marketAddress): Promise<DexlabMarketLpPoonInfo | null> {
    return DexLaMarketbApi.get(`v2/markets/${marketAddress}/pool`)
  }

  // 전체 비인증 마켓리스트 조회
  static async getExtraMarketList(): Promise<DexLabMarketV2Info[] | null> {
    return DexLaMarketbApi.get(`v2/markets?type=UNCERTIFIED,NOT_LISTING`)
  }

  static async getStarAtlasMarketList(): Promise<DexLabMarketV2Info[] | null> {
    return DexLaMarketbApi.get(`v2/markets?type=STAR_ATLAS`)
  }

  // 베이스 토큰주소로 마켓조회
  static async getAllMarketV2ByBaseTokenIds(ids): Promise<DexLabMarketV2Info[] | null> {
    return DexLaMarketbApi.getBody(`v2/markets/base`, ids)
  }

  // 모든 마켓의 전일가 조회
  static async getAllMarketCurrent24hPriceAndLastPrice(): Promise<DexLabRecentPrice[] | null> {
    return DexLaMarketbApi.get(`v2/markets/recent`)
  }
}
