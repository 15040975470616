import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Signer, ConfirmOptions, Connection, Transaction, TransactionSignature, PublicKey } from '@solana/web3.js'
import Wallet from '@project-serum/sol-wallet-adapter'
import { Provider } from '@project-serum/anchor'
import { LoadingOutlined } from '@ant-design/icons'
import logo from '../assets/dexlab_symbol.svg'
import { Button } from 'antd'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
`

const opts: ConfirmOptions = {
  preflightCommitment: 'recent',
  commitment: 'recent',
}

export default function SwapPage() {
  document.title = `Swap - The best DEX platform on SOLANA.`

  return (
    <Wrapper style={{ flex: 1, paddingTop: 60 }}>
      <div style={{ textAlign: 'center' }}>
        <div>
          <img style={{ width: '150px', height: '65px' }} src={logo} alt="Dexlab Trade" />
        </div>
        <div>
          Existing Swap service has been integrated with Dexlab Trade.
          <br />
          More extended features are available on the page below.
          <br />
          <br />
        </div>
        <Button
          type="primary"
          onClick={() => {
            window.location.href = 'https://www.dexlab.space/swap'
          }}
        >
          Dexlab SWAP
        </Button>
      </div>
    </Wrapper>
  )
}

// Cast wallet to AnchorWallet in order to be compatible with Anchor's Provider class
interface AnchorWallet {
  signTransaction(tx: Transaction): Promise<Transaction>
  signAllTransactions(txs: Transaction[]): Promise<Transaction[]>
  publicKey: PublicKey
}

// Custom provider to display notifications whenever a transaction is sent.
//
// Note that this is an Anchor wallet/network provider--not a React provider,
// so all transactions will be flowing through here, which allows us to
// hook in to display all transactions sent from the `Swap` component
// as notifications in the parent app.
class NotifyingProvider extends Provider {
  // Function to call whenever the provider sends a transaction;
  private onTransaction: (tx: TransactionSignature | undefined, err?: Error) => void

  constructor(
    connection: Connection,
    wallet: Wallet,
    opts: ConfirmOptions,
    onTransaction: (tx: TransactionSignature | undefined, err?: Error) => void,
  ) {
    const newWallet = wallet as AnchorWallet
    super(connection, newWallet, opts)
    this.onTransaction = onTransaction
  }

  async send(
    tx: Transaction,
    signers?: Array<Signer | undefined>,
    opts?: ConfirmOptions,
  ): Promise<TransactionSignature> {
    try {
      const txSig = await super.send(tx, signers, opts)
      this.onTransaction(txSig)
      return txSig
    } catch (err: any) {
      if (err instanceof Error || err === undefined) {
        this.onTransaction(undefined, err)
      }
      return ''
    }
  }

  async sendAll(
    txs: Array<{ tx: Transaction; signers: Array<Signer | undefined> }>,
    opts?: ConfirmOptions,
  ): Promise<Array<TransactionSignature>> {
    try {
      const txSigs = await super.sendAll(txs, opts)
      txSigs.forEach((sig) => {
        this.onTransaction(sig)
      })
      return txSigs
    } catch (err: any) {
      if (err instanceof Error || err === undefined) {
        this.onTransaction(undefined, err)
      }
      return []
    }
  }
}
