/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useContext } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { Menu, Select, Popover, Button, Tag } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown } from 'react-bootstrap'
import { useWalletSolBalance } from '../../utils/dex-markets'
import { useWallet } from '../../utils/wallet'
import WalletConnect from '../WalletConnect'
import logo from '../../assets/dexlab_symbol.svg'
import { useTranslation } from 'react-i18next'
import { ThemeContext, isDark } from '../../utils/Contexts/ThemeContext'
import LinkAddress from '../../componentsv2/LinkAddress'
import { CURRENCY_KEY } from '../../utils/types'
import { SOLANA_EXPLORER_URL } from '../../application'

export const LANGUAGES = [
  { name: '한국어', value: 'ko', icon: undefined },
  { name: 'English', value: 'en', icon: undefined },
  { name: 'Spanish', value: 'es', icon: undefined },
  { name: '简体中文', value: 'zh_cn', icon: undefined },
  { name: '繁體中文(台灣)', value: 'zh_tw', icon: undefined },
]

export const CURRENCYS = [
  { name: 'Currency', value: 'USD' },
  { name: 'KRW', value: 'KRW' },
]

const EXTERNAL_LINKS = {
  '/learn': 'https://serum-academy.com/en/serum-dex/',
  '/add-market': 'https://serum-academy.com/en/add-market/',
  '/wallet-support': 'https://serum-academy.com/en/wallet-support',
  '/dex-list': 'https://serum-academy.com/en/dex-list/',
  '/developer-resources': 'https://serum-academy.com/en/developer-resources/',
  '/explorer': SOLANA_EXPLORER_URL,
  '/srm-faq': 'https://projectserum.com/srm-faq',
}

const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
    <div className="profile_log">
      <div className="user">
        <span className="thumb">
          <i className="mdi mdi-account"></i>
        </span>
        <span className="arrow">
          <i className="la la-angle-down"></i>
        </span>
      </div>
    </div>
  </div>
))

function TradeHeader() {
  const [theme] = useContext(ThemeContext)
  const { t: trText, i18n } = useTranslation()
  const [selectLanguage, setSelectLanguage] = useState(i18n.language)
  const [selectCurrency, setSelectCurrency] = useState(localStorage.getItem(CURRENCY_KEY) ?? 'Currency')

  const solBalance = useWalletSolBalance()
  const { connected, wallet, providerName, providerUrl } = useWallet()
  const walletPublicKey = wallet?.publicKey?.toBase58()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setSelectLanguage(localStorage.getItem('language') ?? i18n.language)
  }, [])

  useEffect(() => {
    i18n.changeLanguage(selectLanguage)
    localStorage.setItem('language', selectLanguage)
  }, [i18n, selectLanguage])

  useEffect(() => {
    if (localStorage.getItem(CURRENCY_KEY) !== selectCurrency) {
      localStorage.setItem(CURRENCY_KEY, selectCurrency)
      history.go(0)
    }
  }, [selectCurrency])

  const handleClick = useCallback(
    (e) => {
      if (!(e.key in EXTERNAL_LINKS)) {
        history.push(e.key)
      }
    },
    [history],
  )

  function depositFtxForSOL() {
    if (wallet && connected) {
      const depositCoin = 'SOL'
      const popUrl = `https://ftx.com/pay/request?coin=${depositCoin}&address=${wallet.publicKey.toString()}&tag=&wallet=sol&memoIsRequired=true&memo=Deposit via Dexlab site&fixedWidth=true`
      const popOption = 'width=450, height=700, resizable=no, scrollbars=yes, status=no'
      window.open(popUrl, '', popOption)
    }
  }

  return (
    <>
      <div className="header dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <nav className="navbar navbar-expand-lg navbar-light px-0 justify-content-between">
                <a className="navbar-brand" href={'https://www.dexlab.space'}>
                  {/* <LogoWrapper> */}
                  <img style={{ width: '150px', height: '65px' }} src={logo} alt="Dexlab Trade" />
                  {/* </LogoWrapper> */}
                </a>

                <Menu
                  mode="horizontal"
                  onClick={handleClick}
                  selectedKeys={[location.pathname]}
                  style={{
                    color: isDark(theme) ? '#FFFFFF' : '#484848',
                    borderBottom: 'none',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'flex-end',
                    flex: 1,
                  }}
                >
                  <Menu.Item key={'/swap'}>
                    {trText('swap')} <Tag color="green">v2.0</Tag>
                  </Menu.Item>
                  {connected && <Menu.Item key="/v2/my/balances">{trText('tab_balances')}</Menu.Item>}
                  <Menu.Item key="/trade">
                    <a href={'https://www.dexlab.space/swap'}>
                      DEX Trade
                    </a>
                  </Menu.Item>
                  <Menu.Item key="/mintinglab">
                    <a href={'https://minting.dexlab.space'}>
                      MintingLab
                    </a>
                  </Menu.Item>
                  <Menu.Item key="/analytics">
                    <a href={'https://www.dexlab.space/analytics'}>
                      Analytics
                    </a>
                  </Menu.Item>
                  <Menu.Item key="/help">
                    <a
                      href={
                        i18n.language === 'ko' ? 'https://docs.dexlab.space/v/korean/' : 'https://docs.dexlab.space'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {trText('help')}
                    </a>
                  </Menu.Item>
                </Menu>
                <div className="header-right d-flex my-2 align-items-center">
                  <div className="language" style={{ marginRight: '3px', color: isDark(theme) ? '' : '#484848' }}>
                    <Select
                      style={{ color: isDark(theme) ? '' : '#484848' }}
                      suffixIcon={<DownOutlined style={{ color: isDark(theme) ? '#FFF' : '#000' }} />}
                      onSelect={setSelectLanguage}
                      value={selectLanguage}
                    >
                      {LANGUAGES.map(({ name, value, icon }) => (
                        <Select.Option value={value} key={value}>
                          {icon && <i className={`flag-icon ${icon}`}></i>} {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className="dashboard_log" style={{ marginLeft: '10px' }}>
                    {connected ? (
                      <Dropdown className="profile_log">
                        <Dropdown.Toggle as={ProfileToggle} />
                        <Dropdown.Menu size="sm" title="">
                          <div className="user-email" style={{ width: '350px' }}>
                            <div className="user">
                              <span className="thumb">
                                <i className="mdi mdi-account"></i>
                              </span>
                              <div className="user-info">
                                <h6>
                                  <Link external="true" to={providerUrl}>
                                    {providerName}
                                  </Link>{' '}
                                  wallet{' '}
                                </h6>
                                <Popover
                                  style={{ paddingBottom: '10px' }}
                                  content={<LinkAddress address={walletPublicKey} />}
                                  placement="bottomRight"
                                  title={trText('wallet_public_key_title')}
                                  trigger="click"
                                >
                                  {trText('wallet_public_key_title')}
                                </Popover>
                              </div>
                            </div>
                          </div>
                          {connected ? (
                            <div
                              style={{
                                marginLeft: '45px',
                                marginRight: '10px',
                                marginBottom: '20px',
                                marginTop: '30px',
                              }}
                            >
                              <div style={{ textAlign: 'center' }}>
                                <Button
                                  style={{ height: '40px' }}
                                  type="primary"
                                  shape="round"
                                  onClick={depositFtxForSOL}
                                >
                                  Deposit from FTX
                                </Button>
                                <div style={{ marginTop: '3px' }}>
                                  <span>
                                    SOL Balance: <b>{solBalance.toFixed(4)}SOL</b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <Link to={'/v2/my/orders'} className="dropdown-item">
                            <i className="mdi mdi-cart"></i>
                            <b>{trText('tab_open_order')}</b>
                          </Link>
                          <Link to={'/v2/my/balances'} className="dropdown-item">
                            <i className="mdi mdi-wallet"></i>
                            <b>{trText('tab_balances')}</b>
                          </Link>
                          <a
                            href={providerUrl.replaceAll('/extension', '')}
                            className="dropdown-item"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="mdi mdi-lock"></i>
                            <b>{providerName}</b> wallet
                          </a>
                          <div
                            className="dropdown-item logout"
                            onClick={connected ? wallet.disconnect : wallet.connect}
                          >
                            <i className="mdi mdi-logout"></i> Logout
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <WalletConnect />
                    )}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TradeHeader
